<template>
  <div class="basic-data full-height">
    <v-container class="full-height basic-data-container" fluid>
      <v-row class="button-row">
        <v-col cols="12" class="button-container">
          
            <div class="button-back">
              <router-link :to="{ name: 'home' }" class="plain-link">
                <v-icon size="43" color="black"> mdi-chevron-left</v-icon>
                <span class="button-back-text">
                  {{ $t(`general.navigation.buttons.back`) }}
                </span>
              </router-link>
            </div>
          
          <v-divider
            class="mx-4 invisible-divider"
            vertical
          ></v-divider>
          
          <div class="button-forward">
            <a v-on:click="generateShape()" class="plain-link">
              <span class="button-forward-text">
                {{ $t(`general.navigation.buttons.next`) }}
              </span>
              <v-icon size="43" color="white">mdi-chevron-right</v-icon>
            </a>
          </div>
          
        </v-col>
      </v-row>
      <v-row>
        <v-col
          md="5"
          cols="12"
        >
          <div class="content-left-container">
              <basic-data-size-and-color-picker></basic-data-size-and-color-picker>
          </div>
        </v-col>
        <v-col
          md="7"
          cols="12"
        >
          <div class="content-right-container" style="height: 100%">
            <basic-data-shape-picker></basic-data-shape-picker>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import BasicDataSizeAndColorPicker from '../components/basic-data/BasicDataSizeAndColorPicker.vue'
import BasicDataShapePicker from '../components/basic-data/BasicDataShapePicker.vue'
import router from '../plugins/router';

export default {
  components: { 
    BasicDataSizeAndColorPicker,
    BasicDataShapePicker
  },
  name: 'BasicData',
  data () {
    return {
      
    }
  },
  methods: {
    generateShape() {
      this.$store.dispatch('basicData/setupBasicShapeConfiguration')
      // if (this.$store.getters['basicData/shapeInputsTouched']) {
      //   this.$store.commit('basicData/generateElements')
      // }
      router.push({ name: 'configurator' })
    }
  }
}
</script>